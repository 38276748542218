function findPosition() {
	return (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
}

function checkParameters() {
	var urlParams = new URLSearchParams(window.location.search),
		section = urlParams.get('s') || false;
		return section;
}

function findSection(title) {
	var section = document.getElementById(title),
		fromTop;
	if(section) {
		fromTop = section.offsetHeight;
		window.scroll({
		  top: fromTop,
		  left: 0,
		  behavior: 'smooth'
		})
	}
}

function showSectionTitle( idx ) {
	var dots = document.querySelectorAll('.jsMenu .menu__dot');
	//reset tags
	for (var i = 0; i < dots.length; i++) {
		dots[i].classList.remove('reveal');
	}
	//activate
	dots[idx].classList.add('reveal');
	setTimeout( function() {
		//dismiss
		dots[idx].classList.remove('reveal');
	}, 1000 );
}

function loadCSS( href, before, media ){ 
	var ss = window.document.createElement( "link" ),
	ref = before || window.document.getElementsByTagName( "script" )[ 0 ]; 
	ss.rel = "stylesheet"; 
	ss.href = href; 
	ss.media = "only x"; 
	ref.parentNode.insertBefore( ss, ref ); 
	setTimeout( function(){ 
		ss.media = media || "all"; 
	} ); 
	return ss; 
}

function loadScript( callback, url ) {
	var firstScript = document.getElementsByTagName('script')[0],
		tag = document.createElement('script');
		tag.src = url;
		tag.async = true;
		tag.defer = true;
		tag.onload = function() {
			typeof callback === 'function' ? callback() : false;
		}
	firstScript.parentNode.insertBefore(tag, firstScript);
}

function lazyLoadVideo( player, autoplay ) {
	if( !player.classList.contains('complete') ) {
		var iframe = document.createElement('iframe');
		iframe.src = autoplay ? player.dataset.video + '&autoplay=1' : player.dataset.video;
		player.appendChild(iframe);
		player.classList.add('complete');
	}
}

function lazyLoadImages(load) {
	for (var i = 0; i < imgs.length; i++) {
		var self = imgs[i],
			src = self.getAttribute('data-src'),
			top = self.getBoundingClientRect().top,
			inWindow = load ? top > position && top < (position + windowHeight) : false;

		if( inWindow || !load ) {
			if( !self.classList.contains('loaded') && src.length > 0 ) {
				self.tagName == 'IMG' ? self.setAttribute('src', src) : self.style.backgroundImage = 'url('+src+')';
				self.classList.add('loaded');
			}
		}
	}
}

function throttledScroll() {
	if (throttle) {
		position = findPosition();
		// console.log(position);
		checkFrame();
		checkSection();
		throttle = false;
	}
}

function checkFrame() {
	for (var i = 0; i < frames.length; i++) {
		var frame = frames[i];
		if ( (position + frameInPoint) >= frame.topOf ) {
			frame.self.classList.add('revealed');
			frames.splice(i, 1);
			i--;
		}
	}
}

function checkSection() {
	for (var i = 0; i < sections.length; i++) {
		var section = sections[i],
			inPoint = Number(position + sectionInPoint),
			outPoint = Number(section.topOf + section.heightOf);

		if( inPoint >= section.topOf && inPoint < outPoint) {			
			if( !section.self.classList.contains('active') ) {
				var dots = document.querySelectorAll('.jsMenu .menu__dot');

				for (var x = 0; x < dots.length; x++) {
					dots[x].classList.remove('active');
				}

				if(activeSection ) { activeSection.classList.remove('active'); }
				history.pushState({ page : 1 }, section.title, '#' + section.ref);
				window.dataLayer.push({
					'event':'analyticsEvent',
					'eventCategory': 'Crafted',
					'eventAction': 'Scroll Section',
					'eventLabel': section.title
				});
				section.self.classList.add('active');
				showSectionTitle( i );
				dots[i].classList.add('active');
				activeSection = section.self;
			}
		}
	}
}

function updateActiveSlide() {
	var controls = this.selector.parentElement.querySelector('.carousel__controls'),
		idx = this.currentSlide;
	for (var i = 0; i < controls.children.length; i++) {
		controls.children[i].classList.remove('current');
	}
	controls.children[idx].classList.add('current');
	window.dataLayer.push({
		'event':'analyticsEvent',
		'eventCategory': 'Crafted',
		'eventAction': 'Slide Change',
		'eventLabel': `Slide ${idx}`
	});
}

window.addEventListener('scroll', function() { throttle = true; }, {passive : true});
var revealCheck = setInterval(throttledScroll, 100);

var menu = document.querySelector('.jsMenu'),
	dots = document.querySelectorAll('.jsMenu .menu__dot'),
	imgs = document.querySelectorAll('[data-src]'), //All Lazy loading images
	windowHeight = window.innerHeight, // easyReveal, lazyLoad
	docHeight = (document.documentElement.scrollHeight) - windowHeight, // easyReveal, lazyLoad
	frameInPoint = windowHeight - (windowHeight/8), //80%, easyReveal
	allFrames = document.querySelectorAll('*[class*="reveal-"]'), //easyReveal
	frames = [],
	allSections = document.querySelectorAll('[data-section]'),
	sections = [],
	sectionInPoint = windowHeight - (windowHeight/2), //20%, easyReveal
	activeSection,
	position,
	throttle,
	players = document.querySelectorAll('.video-player'), //lazyLoading YT players
	panoramicButtons = document.querySelectorAll('.jsPanButton'),
	tracked = document.querySelectorAll('[data-track]'),
	requestedSection = checkParameters(),//find query search
	lazyDelay = 5000; //delay before loading, lazyLoad, youtube lazyLoad;

//*********************************
// EasyReveal
//*********************************

allFrames.forEach(function(el) {
	const frame = {
		self : el,
		heightOf : el.clientHeight,
		topOf : el.getBoundingClientRect().top
	};
	frames.push(frame);
});

allSections.forEach(function(el) {
		const section = {
			self : el,
			title : el.dataset.section,
			ref : el.id,
			heightOf : el.getBoundingClientRect().height,
			topOf : el.getBoundingClientRect().top
		};
		sections.push(section);
	menu.innerHTML += `<a href="#${el.id}" class="menu__node"><div class="menu__dot"><div class="core"></div></div><div class="menu__slide">${el.dataset.section}</div></a>`
});

tracked.forEach(function(item) {
	item.addEventListener('click', function(e) {
		var self = e.target,
			label,
			t = self.innerText,
			txt = t || 'No Text',
			tag = self.dataset.track;
			label = tag || txt;

		window.dataLayer.push({
			'event':'analyticsEvent',
			'eventCategory': 'Crafted',
			'eventAction': 'CTA',
			'eventLabel': label
		});

	}, { passive : true });
})

panoramicButtons.forEach(function(btn) {
	btn.addEventListener('click', function() {
		this.closest('.js-pano').classList.toggle('active');
		var title = this.dataset.tag || 'no title provided';
		window.dataLayer.push({
			'event':'analyticsEvent',
			'eventCategory': 'Crafted',
			'eventAction': 'Watch Video',
			'eventLabel': title
		});
	}, { passive : true });
});

setTimeout( checkFrame, 50);
if( requestedSection ) {
	setTimeout( findSection(requestedSection), 50);
}


position = findPosition();

//*********************************
// Image Lazy Load
//*********************************
//Load only the images that are visible on the screen
lazyLoadImages(true); 

//set a timer for what will likely be after the load finishes
var lazyLoad = setTimeout(function() {
		lazyLoadImages(false);
	}, lazyDelay),
	checkScroll = function() {
		// console.log('scroll');
		window.removeEventListener('scroll', checkScroll);
		clearTimeout(lazyLoad);
		lazyLoadImages(false);
	};

//if the user scrolls, initiate loading the rest of the images
window.addEventListener('scroll', checkScroll, {passive : true});

//*********************************
// Lazy Load YouTube PLayers
//*********************************
//If there are video players on the page, loops through and load them lazily, with user override
if( players.length ) {
	//Attach a listener to each video space
	players.forEach(function( player ) {
		player.addEventListener('click', function() {
			lazyLoadVideo( player, true );
		}, { passive: true });
	});
	//Load all videos if there enough time has passed
	var lazy = setTimeout(function() {
		for (var i = 0; i < players.length; i++) {
			lazyLoadVideo( players[i], false );
		}
	}, lazyDelay);	
}

//*********************************
// Siema Carousel setup
//*********************************
//Loop through and setup all carousels
var carousels = document.querySelectorAll('.jsCarousel');
if( carousels.length > 0 ) {
	var initCarousels = function() {
		Siema.prototype.addPagination = function() {
			const children = this.innerElements.length;
			if(children > 1) {
				const controls = document.createElement('div');
				controls.className = 'carousel__controls';
				this.selector.parentElement.appendChild(controls);

				for (let i = 0; i < this.innerElements.length; i++) {
					const btn = document.createElement('button');
					btn.className = i == 0 ? 'current' : '';
					btn.innerHTML = '<span class="textless">carousel control dot</span>';
					btn.addEventListener('click', () => this.goTo(i));
					controls.appendChild(btn);
				}
			}
		}

		for (var i = 0; i < carousels.length; i++) {
			var self = carousels[i],
				thisSiema = new Siema({
					selector: self,
					loop: true,
					onChange: updateActiveSlide
				});
			thisSiema.addPagination();
		}

	}
	//Async call the library if not found
	window.Siema ? initCarousels() : loadScript( initCarousels , 'js/vendor/vendor.siema.min.js' );		
}

loadCSS('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;900&display=swap');
//loadScript( false, 'js/section-debug.js' );